<template>
    <v-toolbar>
        <v-switch
            label="Paid"
            class="text-uppercase text-subtitle-1 mt-4"
            v-model="paid"
            color="primary"
            @change="$emit('action', 'paid', $event)"
        ></v-switch>
        <v-spacer></v-spacer>
        <template v-for="(button, index) in toolBarButtons">
            <v-btn
                color="primary"
                :key="index"
                :disabled="button.disabled"
                @click.stop="$emit('action', button.action)"
            >
                <v-icon class="mr-1">{{ button.icon }}</v-icon
                >{{ button.label }}</v-btn
            >
            <v-divider
                vertical
                class="mx-3"
                :key="`divider-${index}`"
            ></v-divider>
        </template>
    </v-toolbar>
</template>
<script>
export default {
    name: "invoice-toolbar",
    components: {},
    props: {
        edit: {
            required: false,
            default: false,
        },
    },
    data() {
        return {
            paid: null,
            toolBarButtons: [
                {
                    label: "Edit",
                    action: "edit",
                    icon: "mdi-pencil",
                    disabled: false,
                },
                {
                    label: "Send",
                    action: "send",
                    icon: "mdi-email-send",
                    disabled: false,
                },
                {
                    label: "Fax",
                    action: "fax",
                    icon: "mdi-fax",
                    disabled: false,
                },
                {
                    label: "Print",
                    action: "print",
                    icon: "mdi-printer",
                    disabled: false,
                },
                {
                    label: "Pay",
                    action: "pay",
                    icon: "mdi-credit-card",
                    disabled: false,
                },
            ],
        };
    },

    watch: {
        edit: {
            immediate: true,
            handler(value) {
                this.toolBarButtons = this.toolBarButtons.map((button) => {
                    if (button.action === "edit") {
                        button.disabled = value;
                    }
                    return button;
                });
            },
        },
    },
};
</script>

<style scoped>
</style>