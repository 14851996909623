var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    [
      _c("v-switch", {
        staticClass: "text-uppercase text-subtitle-1 mt-4",
        attrs: { label: "Paid", color: "primary" },
        on: {
          change: function($event) {
            return _vm.$emit("action", "paid", $event)
          }
        },
        model: {
          value: _vm.paid,
          callback: function($$v) {
            _vm.paid = $$v
          },
          expression: "paid"
        }
      }),
      _c("v-spacer"),
      _vm._l(_vm.toolBarButtons, function(button, index) {
        return [
          _c(
            "v-btn",
            {
              key: index,
              attrs: { color: "primary", disabled: button.disabled },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("action", button.action)
                }
              }
            },
            [
              _c("v-icon", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(button.icon))
              ]),
              _vm._v(_vm._s(button.label))
            ],
            1
          ),
          _c("v-divider", {
            key: "divider-" + index,
            staticClass: "mx-3",
            attrs: { vertical: "" }
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }